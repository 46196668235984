import React, { useContext, useEffect, useState } from "react";
import DeviceDetails from "./views/Devicedetails";
import { useParams } from "react-router-dom";
import { Device } from "../../../interfaces/Device";
import { AiFillCloseCircle } from "react-icons/ai";
import UpdateWebHook from "./views/UpdateWebHook";
import ChangeDeviceToken from "./views/ChangeDeviceToken";
import DevicedetailsWH from "./views/DevicedetailsWH";
import TimeLineQueue from "./views/TimeLineQueue";
import { DeviceMasterContext } from "../../../context/devices/DeviceMasterContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

const DeviceUseDetails: React.FC = () => {
  const { rolePrivileges } = useContext(AuthContext);
  const { deviceId } = useParams<{ deviceId: string }>();
  const { deviceData } = useContext(DeviceMasterContext);
  const [deviceDetails, setDeviceDetails] = useState<Device | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (deviceData && deviceData.data) {
      const foundDevice = deviceData.data.find(
        (device: Device) => device.deviceId === deviceId
      );
      if (foundDevice) {
        setIsOpen(true);
        setDeviceDetails(foundDevice);
        console.log(true)
      } else {
        console.warn("Device not found in deviceData.");
      }
    }
  }, [deviceData, deviceId]);

  const handleClose = () => {
    setIsOpen(false);
    setDeviceDetails(undefined);
    navigate(`/whatsapp`);
  };

  return (
    <div className="App">
      {isOpen && deviceDetails && (
        <div
          className="relative z-10"
          aria-labelledby="slide-over-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 z-0"></div>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <div className="pointer-events-auto w-screen max-w-full sm:max-w-xl">
                  <div className="flex h-full rounded-l-xl flex-col overflow-y bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-start border-b pb-2 border-gray-300 justify-between">
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={handleClose}
                          >
                            <AiFillCloseCircle color="#f87171" size={30} />
                          </button>
                        </div>
                        <h2
                          className="flex gap-1 text-lg font-medium text-gray-900"
                          id="slide-over-title"
                        >
                          {deviceDetails.name}
                        </h2>
                      </div>
                      <div className="mt-8">
                        <div className="flow-root">
                          <ul className="-my-6 divide-y divide-gray-200">
                            <li>
                              <ChangeDeviceToken />
                            </li>
                            {/* {rolePrivileges? === 3 ?  */}
                            {rolePrivileges === 3 && (
                              <li>
                                <DeviceDetails />
                              </li>
                            )}

                            <li>
                              <DevicedetailsWH />
                            </li>
                            <li>
                              <UpdateWebHook />
                            </li>
                            <li>
                              <TimeLineQueue />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceUseDetails;
